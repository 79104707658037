import React, { Suspense, useEffect, useState, lazy } from 'react'
import AOS from 'aos'
import { Spinner } from 'react-bootstrap'

import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const Header = lazy(() => import('../components/Header'))
const FirstScreen = lazy(() => import('../components/MainScreen'))
const Services = lazy(() => import('../components/Services'))
const Tile = lazy(() => import('../components/PhotosTile'))
const Footer = lazy(() => import('../components/Footer'))

const App = () => {
  const [isOpened, setOpen] = useState(false)

  const onClickHandler = () => {
    document.body.style.overflow = 'auto'
    setOpen(false)
  }

  window.onload = () => {
    console.log(
      '%cgelios%c.%ctech ©',
      'font-family: "Gilroy"; font-size: 30px;color:#086b6b',
      'font-family: "Gilroy"; font-size: 30px;color:#464646',
      'font-family: "Gilroy"; font-size: 30px;color:#086b6b',
    )

    const anchors = document.querySelectorAll('a[href*="#"]')

    for (let anchor of anchors) {
      anchor.addEventListener('click', (e) => {
        e.preventDefault()

        const blockID = anchor.getAttribute('href').substr(1)

        document.getElementById(blockID).scrollIntoView()
      })
    }
  }

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 50,
    })
  })

  return (
    <Suspense
      fallback={
        <div id='loader-wrapper'>
          <Spinner id='loader' animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </div>
      }>
      <Header fromMain={isOpened} openFunc={setOpen} />
      <main onClick={onClickHandler}>
        <FirstScreen />
        <Services />
        <Tile />
      </main>
      <Footer />
    </Suspense>
  )
}

export default App
